import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFormState } from "../main/ActivationForm/Context/ActivationFormContext";

type SubscriptionContentProps = {
  title: string
}

const SidebarInfoBlockContent: React.FC<SubscriptionContentProps> = ({title}) => {
  const {t} = useTranslation();
  const { subscriptionIds, orderNumber, regionName } = useFormState();

  return (
    <div>
      <h2 className={"title6"}>{title}</h2>
      {orderNumber && 
        <p key={"order"} className={"subinfo"}>
          <span className={"title8"}>{t('subscriptionBlock.orderNum', "Order Number") + ": "}</span>{orderNumber.split('c')[0]}
        </p>
      }
      {subscriptionIds && subscriptionIds.map((id, index) => (
        <p key={index} className={"subinfo"}>
          <span className={"title8"}>{t('subscriptionBlock.subId', "Subscription ID") + ": "}</span>{id}
        </p>
      ))}
      {
        regionName
          && regionName!== ''
          && <p>
            <span className="title8">{t('subscriptionBlock.dcrTitle', "Data Center Region")}: </span>{regionName}
          </p>
      }
    </div>
  );
};

export default SidebarInfoBlockContent;
