
const hostname = window.location.hostname;
const windowURL = new URLSearchParams(window.location.search);
const theme = windowURL.get('theme');

export const themeOn = hostname.toLowerCase().includes('oc17') || (theme && theme.length > 0);

export const base_url = (hostname === 'localhost' && process.env.HOSTNAME !== 'PINTLAB') ? "http://localhost:24000/20200828"
                      : hostname === 'activate-stage.oraclecloud.com' ? "https://signup-api-stage.oraclecloud.com:8443/20200828"
                      : hostname === 'activate-miglab.oraclecloud.com' ? "https://signup-api-stage.oraclecloud.com:7443/20200828"
                      : hostname === 'activate.oraclecloud.com' ? "https://signup-api.oraclecloud.com/20200828"
                      : hostname === 'activate-pool2.oraclecloud.com' ? "https://signup-api-stage.oraclecloud.com:9443/20200828"
                      : (hostname === 'activate-pintlab.oraclecloud.com' || (hostname === 'activate-localhost.oraclecloud.com' && process.env.REACT_APP_ENV === 'PINTLAB')) ? "https://signup-api-stage.oraclecloud.com:443/20200828"
                      : hostname === 'activate.cloud.oracle.com' ? "https://signup-api.oraclecloud.com/20200828"
                      : hostname === 'activate-pintlab2.oraclecloud.com' ? "https://signup-api-stage.oraclecloud.com:6443/20200828"
                      : hostname.toLowerCase().includes('oc17') ? "https://signup.us-dcc-phoenix-1.oci.oraclecloud17.com/20200828"
                      : hostname.toLowerCase().includes('psn-pco.it') ? "https://signup.eu-dcc-rome-1.oci.psn-pco.it/20200828/" : "";

export const env = (hostname === 'localhost' && process.env.HOSTNAME !== 'PINTLAB') ? "dev"
                      : hostname === 'activate-stage.oraclecloud.com' ? "stg"
                      : hostname === 'activate-miglab.oraclecloud.com' ? "uat"
                      : hostname === 'activate-pool2.oraclecloud.com' ? "uat3"
                      : (hostname === 'activate-pintlab.oraclecloud.com' || (hostname === 'activate-localhost.oraclecloud.com' && process.env.REACT_APP_ENV === 'PINTLAB')) ? "uat"
                      : hostname === 'activate.oraclecloud.com' ? "prd"
                      : hostname === 'activate.cloud.oracle.com' ? "prd"
                      : hostname === 'activate-pintlab2.oraclecloud.com' ? "uat2"
                      : hostname.toLowerCase().includes('oc17') ? "oc17"
                      : hostname.toLowerCase().includes('psn-pco.it') ? "oc22" : "";

export const signup_environment  = (hostname === 'localhost' && process.env.HOSTNAME !== 'PINTLAB') ? "dev"
                      : hostname === 'activate-stage.oraclecloud.com' ? "stage"
                      : hostname === 'activate-miglab.oraclecloud.com' ? "miglab"
                      : hostname === 'activate-pool2.oraclecloud.com' ? "pool2"
                      : (hostname === 'activate-pintlab.oraclecloud.com' || (hostname === 'activate-localhost.oraclecloud.com' && process.env.REACT_APP_ENV === 'PINTLAB')) ? "pintlab"
                      : hostname === 'activate.oraclecloud.com' ? "prd"
                      : hostname === 'activate.cloud.oracle.com' ? "prd"
                      : hostname === 'activate-pintlab2.oraclecloud.com' ? "pintlab2"
                      : hostname.toLowerCase().includes('oc17') ? "prd"
                      : hostname.toLowerCase().includes('psn-pco.it') ? "prd" : "";

export const courier_service_url = (hostname === 'localhost' && process.env.HOSTNAME !== 'PINTLAB') ? 'https://courier-service-stage.oraclecloud.com/20200828'
                      : hostname === 'activate-stage.oraclecloud.com' ? 'https://courier-service-stage.oraclecloud.com/20200828'
                      : hostname === 'activate-miglab.oraclecloud.com' ? 'https://courier-service-stage.oraclecloud.com/20200828'
                      : hostname === 'activate-pool2.oraclecloud.com' ? 'https://courier-service-stage.oraclecloud.com/20200828'
                      : (hostname === 'activate-pintlab.oraclecloud.com' || (hostname === 'activate-localhost.oraclecloud.com' && process.env.REACT_APP_ENV === 'PINTLAB')) ? 'https://courier-service-stage.oraclecloud.com/20200828'
                      : (hostname === 'activate-pintlab2.oraclecloud.com' || (hostname === 'activate-pintlab2.oraclecloud.com' && process.env.REACT_APP_ENV === 'PINTLAB2')) ? 'https://courier-service-stage.oraclecloud.com/20200828'
                      : hostname === 'activate.oraclecloud.com' ? 'https://courier-service.oraclecloud.com/20200828'
                      : hostname === 'activate.cloud.oracle.com' ? 'https://courier-service.oraclecloud.com/20200828'
                      : hostname.toLowerCase().includes('oc17') ? 'https://courier-service.oraclecloud.com/20200828'
                      : hostname.toLowerCase().includes('psn-pco.it') ? 'https://courier-service.oraclecloud.com/20200828' : '';

export const ux_plugin_url = (hostname.includes('localhost') || hostname.includes('pintlab')) && themeOn ? "https://console.r1.oracleiaas.com/plugins/ux-config/"
                      : hostname.includes('stage') && themeOn ? "https://cloud-staging.oracle.com/plugins/ux-config-staging/"
                      : hostname === 'activate.cloud.oracle.com' && themeOn? "https://cloud.oracle.com/plugins/ux-config/"
                      : hostname.toLowerCase().includes('oc17') ? "https://oc17.cloud.oracle.com/plugins/ux-config/console-home/"
                      : hostname.toLowerCase().includes('psn-pco.it') ? "https://cloud.psn-pco.it/plugins/ux-config/console-home/" : "";