import * as React from "react";
import { useFormState } from "../main/ActivationForm/Context/ActivationFormContext";

const ActivationPageSidebar: React.FC<{children: any}> = ({children}) => {
  const { processing, showThankYou, nonAuth, loading, showBanner, nonPending, tokenHasExpired, fawError } = useFormState();
  return (
    <>
    {!processing && !showThankYou && !nonAuth && !loading && !showBanner && !nonPending && !tokenHasExpired && !fawError &&
      <div className="col-md-4 col-xs-12 sidebar">
        {children}
      </div>
    }
    </>
  );
};

export default ActivationPageSidebar;
