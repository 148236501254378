import {base_url, ux_plugin_url} from '../config/apiConfig';
import * as _ from 'lodash';

export const fetchOrders = (token) => {
  return fetch(
    `${base_url}/orders?token=${token}`,
    {
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    }
  );
};

export const fetchAccountNameValidation = (nameAttempt) => {
    //Get JWT toke from url query
    const token = new URLSearchParams(window.location.search).get('activationToken');
    const decoded = jwt2Obj(token);
    const lHeaders = _.get(decoded, 'version', '') === 'V2'
        ? {'Content-Type': 'application/json', 'api-flow': 'PLC'}
        : {'Content-Type': 'application/json'};
  return fetch(
    `${base_url}/validateAccountName`,
    {
      method: 'POST',
      headers: lHeaders,
      body: JSON.stringify({ name: nameAttempt })
    }
  );
};

export const fetchBanner = () => {
  return fetch(
    `${base_url}/banner`,
    {
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    }
  );
};

export const fetchRegions = (path) => {
    const clientIdParam = path.includes('?') && path.includes('=') ? `&clientId=ActivateUI` : '?clientId=ActivateUI';
    return fetch(
        `${base_url}${path}${clientIdParam}`,
        {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
    });
};

export const fetchCreateTenancy = (token, body) => {
    const decoded = jwt2Obj(token);
    const lHeaders = _.get(decoded, 'version', '') === 'V2'
                ? {'Content-Type': 'application/json', 'api-flow': 'PLC'}
                : {'Content-Type': 'application/json'};

  return fetch(
    `${base_url}/tenancies?token=${token}`,
    {
      method: 'POST',
      headers: lHeaders,
      body: body
    }
  );
};

export const fetchAsset = (path) => {
    return fetch(
        `${ux_plugin_url}${path}`,
        { method: 'GET' }
    );
};

const jwt2Obj = (token) => {
    let result;
    try {
        result = JSON.parse(Buffer.from(_.split(token, '.')[1], 'base64').toString());
    } catch {
        result = null;
    }
    return result;
};

