import * as React from 'react';
import './Notifier.css';
import Icon from "../Icon/Icon";
import {Trans} from "react-i18next";

export interface NotifierProps {
    closeButton?: boolean,
    message: string,
    position: string,
    type: string
};

const Notifier: React.FC<NotifierProps> = ({ closeButton, message, position, type }) => {
    const [showNotifier, setShowNotifier] = React.useState(true)
    const closeNotifier = () => {
        setShowNotifier(false)
    };

    return (
        <>
        {showNotifier &&
            <div className="notifierContainer">
                <div className="row">
                    <div className="col-xs-11">
                        <Icon name={type}/>
                        <h4>{message}</h4>
                    </div>
                    <div className="col-xs-1">
                        {closeButton &&
                        <div className="closeBtn">
                            <Icon name="close" onClick={closeNotifier()}/>
                        </div>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        {position === 'apiError' &&
                            <p><Trans i18nKey="activate.apiErrorDetails">Some data for the Oracle Cloud Account Activation may not be available. Reload page or retry the operation and if the problem persists, then <a href="https://oc-cx-en.custhelp.com/app/chat/chat_launch" target='_blank' rel="noopener noreferrer">contact Oracle Support</a></Trans></p>
                        }
                        {position === 'createError' &&
                            <p><Trans i18nKey="activate.createErrorDetails">Oracle Cloud Account activation did not correctly process all of your submitted information. Verify its accuracy and try again. If the problem persists, <a href="https://oc-cx-en.custhelp.com/app/chat/chat_launch" target='_blank' rel="noopener noreferrer">contact Oracle Support</a>.</Trans></p>
                        }
                    </div>
                </div>
            </div>
        }
        </>
    )
};

export default Notifier;
