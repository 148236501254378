import * as React from "react";

type IntroBlockContentProps = {
  title: string,
  content: any
}

const SidebarInfoBlockContent: React.FC<IntroBlockContentProps> = ({title, content, children}) => {
  return (
    <div>
      <h2>{title}</h2>
      <p>{content}</p>
      {children}
    </div>
  );
};

export default SidebarInfoBlockContent;
